(<template>
  <div class="button-search-filter">
    <button class="button-search-filter__show-btn"
            @click="showFilter">
      <span class="button-search-filter__show-btn--text">{{ reference || buttonText }}</span>
      <span class="button-search-filter__show-btn--arrow"></span>
    </button>
    <div v-if="filterActive"
         v-click-outside="handleClickOutside"
         class="button-search-filter__wrapper">
      <span class="button-search-filter__subtitle">{{ $gettext('Filter') }}</span>
      <sk-input :placeholder="placeholderText"
                :icon="'search'"
                :preselected-value="reference"
                :margin-bottom="false"
                class="button-search-filter__input"
                @fieldvaluechanged="catchReferenceSearch" />
      <sk-radio v-for="filter in filterOptions"
                :key="filter.value"
                :item-text="filter.text"
                :item-value="filter.value"
                :preselected-value="selectedFilter"
                class="button-search-filter__radio"
                @radiochanged="changeFilter" />
      <div class="button-search-filter__buttons">
        <button class="button-search-filter__cancel-btn"
                @click="hideFilter">{{ $gettext('Cancel') }}</button>
        <button class="sk-btn sk-btn--default button-search-filter__apply-btn"
                @click="applyFilter">{{ $gettext('Apply') }}</button>
      </div>
    </div>
  </div>
</template>)

<script>
  export default {
    props: {
      buttonText: {
        type: String,
        default: ''
      },
      reference: {
        type: String,
        default: ''
      },
      selectedFilter: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        filterActive: false,
      };
    },
    computed: {
      filterOptions() {
        return [
          {value: 'starts_with', text: 'Starts with'},
          {value: 'exact_match', text: 'Exact match'}
        ];
      },
      placeholderText() { return this.$gettext('Value'); },
    },
    methods: {
      catchReferenceSearch(value) {
        this.$emit('changesearchvalue', value);
      },
      changeFilter(value) {
        this.$emit('changefiltervalue', value);
      },
      hideFilter() {
        this.filterActive = false;
        this.$emit('hidefilter');
      },
      applyFilter() {
        this.$emit('applyfilter');
        this.filterActive = false;
      },
      showFilter() {
        this.filterActive = !this.filterActive;
      },
      handleClickOutside() {
        this.hideFilter();
      }
    },
  };
</script>

<style scoped>
.button-search-filter {
  position: relative;
}

.button-search-filter__show-btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 35px;
  border: 1px solid #ddd;
  text-align: left;
}

.button-search-filter__show-btn--text {
  overflow: hidden;
  padding: 0 10px;
  line-height: 33px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button-search-filter__show-btn--arrow {
  position: relative;
  width: 25px;
  height: 35px;
}

.button-search-filter__show-btn--arrow::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 12px;
  box-sizing: border-box;
  width: 7px;
  height: 1px;
  background-color: #a7abbd;
  -webkit-transform: rotate(43deg);
  transform: rotate(43deg);
}

.button-search-filter__show-btn--arrow::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 7px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 7px;
  height: 1px;
  background-color: #a7abbd;
  -webkit-transform: rotate(-43deg);
  transform: rotate(-43deg);
}

.button-search-filter__wrapper {
  position: absolute;
  top: 35px;
  z-index: 3;
  width: 270px;
  padding: 10px;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 34, 102, 0.1), 0 1px 2px 0 rgba(0, 34, 102, 0.2);
}

.button-search-filter__subtitle {
  color: #333;
  font-weight: bold;
  font-size: 12px;
}

.button-search-filter__input {
  margin: 10px 0;
}

.button-search-filter__buttons {
  display: flex;
}

.button-search-filter__radio {
  margin: 10px 8px 10px 0;
}

.button-search-filter__cancel-btn {
  margin-right: 8px;
  margin-left: auto;
  padding: 4px 8px;
  color: #ff5b24;
  font-weight: 500;
}

.button-search-filter__apply-btn {
  width: 55px;
}
</style>
