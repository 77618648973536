(<template>
  <page-data-section class="invoices">
    <div class="invoices__header">
      <h3>{{ sectionTitle }}</h3>
      <div class="invoices__sort-by-filter sort-by-filter">
        <p class="sort-by-filter__text">{{ $gettext('Sort by:') }}</p>
        <sk-select :preselected-value="invoicesFilters.sortBy"
                   :items-list="sortByfilters"
                   class="sort-by-filter__select"
                   @csvaluechanged="changeSortBy" />
      </div>
      <button class="sk-btn sk-btn--white invoices__export-button"
              @click="exportInvoices">{{ $gettext('Export CSV') }}</button>
    </div>
    <div class="invoices__filters">
      <sk-select :preselected-value="typeFilter"
                 :items-list="typeFilters"
                 :default-value="defaultTypeValue"
                 class="invoices__search-filter"
                 @csvaluechanged="changeTypeFilter" />
      <sk-input :placeholder="documentIdSearchText"
                :icon="'search'"
                :reg-exp="/[^0-9]/g"
                :preselected-value="documentId"
                :margin-bottom="false"
                class="invoices__search-filter"
                @fieldvaluechanged="value => handleSearchField(value, 'documentId')" />
      <sk-input :placeholder="assignmentIdSearchText"
                :icon="'search'"
                :reg-exp="/[^0-9]/g"
                :preselected-value="assignmentId"
                :margin-bottom="false"
                class="invoices__search-filter"
                @fieldvaluechanged="value => handleSearchField(value, 'assignmentId')" />
      <sk-input :placeholder="orgNumberSearchText"
                :icon="'search'"
                :reg-exp="/[^0-9]/g"
                :preselected-value="orgNumber"
                :margin-bottom="false"
                class="invoices__search-filter"
                @fieldvaluechanged="value => handleSearchField(value, 'orgNumber')" />
    </div>
    <div class="invoices__filters">
      <sk-select :preselected-value="serviceFilter"
                 :items-list="serviceFilters"
                 :default-value="defaultServiceValue"
                 class="service-filter"
                 @csvaluechanged="changeServiceFilter" />
      <reference-search-box :button-text="bookerReferenceButtonText"
                            :reference="bookerReference"
                            :selected-filter="selectedBookerRefFilter"
                            class="invoices__reference-filter"
                            @changesearchvalue="changeBookerRefSearch"
                            @changefiltervalue="changeBookerRefFilter"
                            @hidefilter="hideReferenceFilter('booker')"
                            @applyfilter="applyReferenceFilter" />
      <reference-search-box :button-text="orderReferenceButtonText"
                            :reference="orderReference"
                            :selected-filter="selectedOrderRefFilter"
                            class="invoices__reference-filter"
                            @changesearchvalue="changeOrderRefSearch"
                            @changefiltervalue="changeOrderRefFilter"
                            @hidefilter="hideReferenceFilter('order')"
                            @applyfilter="applyReferenceFilter" />
      <monthly-range-picker v-for="(date, index) in datesList"
                            :key="index"
                            :description-from="date.descriptionFrom"
                            :description-to="date.descriptionTo"
                            :from="date.startDate"
                            :to="date.endDate"
                            class="invoices__date-filter date-filter"
                            @startdatechanged="date.startDate.dateChanged"
                            @enddatechanged="date.endDate.dateChanged" />
    </div>
    <div v-if="customerInvoices.length">
      <page-data-table :data="customerInvoices"
                       :cells="tableCells"
                       :is-downloadable="true"
                       :stripped="false"
                       :cell-header-break="true"
                       class="invoices__table"
                       @downloaditem="downloadInvoice" />
      <div class="invoices__pagination-cont">
        <items-amount class="invoices__item-per-page"
                      @amountchanged="setPages" />
        <sk-pagination :current="+page"
                       :total="+pages"
                       class="invoices__pagination"
                       @prev="toPrevPage"
                       @next="toNextPage"
                       @newpage="toNewPage" />
      </div>
    </div>
    <div v-else
         class="invoices-table__empty-block">
      <div class="invoices-table__empty-block-image"></div>
      <h4 class="invoices-table__empty-block-subtitle">{{ $gettext('No invoices') }}</h4>
      <p class="invoices-table__empty-block-text">{{ noInvoicesText }}</p>
    </div>
  </page-data-section>
</template>)

<script>
  import {mapState, mapMutations} from 'vuex';
  import ItemsAmount from '@/components/shared_components/ItemsAmount';
  import PageDataSection from '@/components/shared_components/page_data/PageDataSection';
  import PageDataTable from '@/components/shared_components/page_data/PageDataTable';
  import MonthlyRangePicker from '@/components/statistics/MonthlyRangePicker';
  import ReferenceSearchBox from '@/components/enterprise/invoices/ReferenceSearchBox';

  export default {
    asyncData({store, route}) {
      let promisesArr = [];
      const params = {
        page: store.state.TTInvoicesStore.customerInvoices.currentPage || +route.query.page,
        items: store.state.filterItemsAmount || 10
      };

      if (!store.state.TTInvoicesStore.customerInvoices.invoices) {
        promisesArr = [...promisesArr, store.dispatch('TTInvoicesStore/getCustomerInvoices', params)];
      }

      return promisesArr ? Promise.all(promisesArr) : '';
    },
    components: {
      'items-amount': ItemsAmount,
      'page-data-section': PageDataSection,
      'page-data-table': PageDataTable,
      'reference-search-box': ReferenceSearchBox,
      'monthly-range-picker': MonthlyRangePicker
    },
    data() {
      return {
        currentPage: this.$route.query.page || 1,
        requestTimer: '',
      };
    },
    computed: {
      ...mapState('TTInvoicesStore', {
        customerInvoicesData: (state) => state.customerInvoices || {},
        invoicesFilters: (state) => state.invoicesFilters,
        documentId: (state) => state.documentIdSearchQuery,
        assignmentId: (state) => state.assignmentIdSearchQuery,
        orgNumber: (state) => state.orgNumberSearchQuery,
        bookerReference: (state) => state.bookerSearchQuery,
        selectedBookerRefFilter: (state) => state.selectedBookerRefFilter,
        orderReference: (state) => state.orderSearchQuery,
        selectedOrderRefFilter: (state) => state.selectedOrderRefFilter
      }),
      sortByfilters() {
        return [
          {id: 'date desc', name: this.$gettext('Most recent')},
          {id: 'date asc', name: this.$gettext('Oldest')},
          {id: 'org_number', name: this.$gettext('Org number')},
          {id: 'document_id', name: this.$gettext('Document ID')}
        ];
      },
      typeFilters() {
        return [
          {id: 'all', name: this.$gettext('All')},
          {id: 'invoice', name: this.$gettext('Invoice')},
          {id: 'credit_note', name: this.$gettext('Credit note')}
        ];
      },
      serviceFilters() {
        return [
          {id: 'all', name: this.$gettext('All')},
          {id: 'interpretation', name: this.$gettext('Interpretation')},
          {id: 'translation', name: this.$pgettext('translation', 'Translation')}
        ];
      },
      tableCells() {
        return [
          {
            name: this.$gettext('Type'),
            dataField: 'type',
            type: 'custom',
            customFieldFunc: this.genTypeFieldText
          },
          {
            name: this.$gettext('ID'),
            dataField: 'documentId',
            type: 'custom',
          },
          {
            name: this.$gettext('Org number'),
            dataField: 'orgName',
            dataFieldExtended: 'orgNumber',
            type: 'combined',
            classList: ['bold-table-cell', 'break-all-words'],
          },
          {
            name: this.$gettext('Booker reference'),
            dataField: 'bookerReference',
            type: 'custom',
            classList: ['break-all-words'],
          },
          {
            name: this.$gettext('Order reference'),
            dataField: 'orderReference',
            type: 'custom',
            classList: ['break-all-words'],
          },
          {
            name: this.$gettext('Date'),
            dataField: 'date',
            type: 'date',
            dateFormat: 'DD/MM/YY',
          },
          {
            name: this.$gettext('Amount'),
            dataField: 'amount',
            type: 'custom',
            customFieldFunc: this.genPriceFieldText
          },
          {
            name: this.$gettext('VAT'),
            dataField: 'vatAmount',
            type: 'custom',
            customFieldFunc: this.genPriceFieldText
          },
          {
            name: this.$gettext('Total'),
            dataField: 'totalAmount',
            type: 'custom',
            classList: ['bold-table-cell'],
            customFieldFunc: this.genPriceFieldText
          },
        ];
      },
      datesList() {
        return {
          dateRange: {
            ...this.getRangeObject({
              descriptionFrom: this.dateFromText,
              descriptionTo: this.dateToText,
              startDate: this.startDate,
              endDate: this.endDate,
              mutationFunc: this.setDateRange
            })
          }
        };
      },
      dateFromText() { return this.$gettext('Date from'); },
      dateToText() { return this.$gettext('Date to'); },
      defaultTypeValue() { return this.$gettext('Type'); },
      defaultServiceValue() { return this.$gettext('Service'); },
      selectDescription() { return this.$gettext('Sort by'); },
      customerInvoices() { return this.customerInvoicesData.invoices || []; },
      startDate() { return this.invoicesFilters.dateFrom; },
      endDate() { return this.invoicesFilters.dateTo; },
      typeFilter() { return this.invoicesFilters.type; },
      serviceFilter() { return this.invoicesFilters.service; },
      page() { return this.customerInvoicesData.currentPage || 0; },
      pages() { return this.customerInvoicesData.pages || 0; },
      filterItems() { return this.$store.state.filterItemsAmount; },
      sectionTitle() { return this.$gettext('Invoices'); },
      bookerReferenceButtonText() { return this.$gettext('Booker reference'); },
      orderReferenceButtonText() { return this.$gettext('Order reference'); },
      documentIdSearchText() { return this.$gettext('Document ID'); },
      assignmentIdSearchText() { return this.$gettext('Assignment ID'); },
      orgNumberSearchText() { return this.$gettext('Org number'); },
      noInvoicesText() { return this.$gettext('There are no invoices to show. You can try adjusting filters or reloading this page.'); },
    },
    watch: {
      invoicesFilters: {
        deep: true,
        handler() {
          this.refetchData();
        }
      },
      $route() {
        this.refetchData();
      }
    },
    methods: {
      ...mapMutations('TTInvoicesStore', [
        'removeCustomerInvoicesData',
        'clearFilters',
        'setDateRange',
        'changeSortBy',
        'changeTypeFilter',
        'changeOrgNumberSearch',
        'changeAssignmentIdSearch',
        'changeDocumentIdSearch',
        'changeServiceFilter',
        'changeBookerRefSearch',
        'changeBookerRefFilter',
        'changeOrderRefSearch',
        'changeOrderRefFilter'
      ]),
      setStartDate(startDateMoment, endDateMoment, mutationFunc) {
        const startDate = startDateMoment.format('YYYY-MM-DD');
        const endDate = startDateMoment.isBefore(endDateMoment)
          ? endDateMoment.format('YYYY-MM-DD')
          : startDateMoment.add(1, 'months').endOf('month').format('YYYY-MM-DD');
        mutationFunc({startDate, endDate});
      },
      setEndDate(startDateMoment, endDateMoment, mutationFunc) {
        const startDate = this.$moment(startDateMoment).format('YYYY-MM-DD');
        const endDate = endDateMoment.format('YYYY-MM-DD');
        mutationFunc({startDate, endDate});
      },
      getRangeObject({startDate, endDate, mutationFunc}) {
        return {
          startDate: {
            ...this.getDateObject(startDate),
            dateChanged: (startDateMoment) => {
              const endDateMoment = this.$moment(endDate, 'YYYY-MM-DD');
              this.setStartDate(startDateMoment, endDateMoment, mutationFunc);
            }
          },
          endDate: {
            ...this.getDateObject(endDate),
            dateChanged: (endDateMoment) => {
              const startDateMoment = this.$moment(startDate, 'YYYY-MM-DD');
              this.setEndDate(startDateMoment, endDateMoment, mutationFunc);
            }
          }
        };
      },
      getDateObject(date) {
        return {
          defaultMonth: this.$moment(date, 'YYYY-MM-DD').month() + 1,
          defaultYear: this.$moment(date, 'YYYY-MM-DD').year()
        };
      },
      genPriceFieldText(dataField) {
        return this.$gettextInterpolate('%{total} NOK', {total: dataField});
      },
      genTypeFieldText(dataField) {
        const fieldTexts = {
          invoice: this.$gettext('Invoice'),
          credit_note: this.$gettext('Credit note')
        };

        return fieldTexts[dataField];
      },
      refetchData() {
        this.removeCustomerInvoicesData();
        this.$store.commit('GlobalStore/startProgress');
        this.$options.asyncData({
          store: this.$store,
          route: this.$route
        }).then(() => {
          this.$store.commit('GlobalStore/stopProgress');
        });
      },
      hideReferenceFilter(filter) {
        if (filter === 'order') {
          this.changeOrderRefSearch('');
          this.changeOrderRefFilter('starts_with');
        } else {
          this.changeBookerRefSearch('');
          this.changeBookerRefFilter('starts_with');
        }
      },
      applyReferenceFilter() {
        this.$store.state.TTInvoicesStore.customerInvoices.currentPage = 1;
        this.refetchData();
      },
      handleSearchField(value, field) {
        clearInterval(this.requestTimer);

        switch (field) {
          case 'documentId':
            this.changeDocumentIdSearch(value);
            break;
          case 'assignmentId':
            this.changeAssignmentIdSearch(value);
            break;
          case 'orgNumber':
            this.changeOrgNumberSearch(value);
            break;
          default:
            return;
        }

        this.requestTimer = setTimeout(() => {
          this.$store.state.TTInvoicesStore.customerInvoices.currentPage = 1;
          this.refetchData();
        }, 700);
      },
      exportInvoices() {
        this.$store.dispatch('TTInvoicesStore/downloadInvoices').then((csvData) => {
          const blob = new Blob([csvData], {type: 'text/csv'});

          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = 'invoices-statistics.csv';
          downloadLink.click();
        });
      },
      downloadInvoice(file) {
        const link = document.createElement('a');
        const pdfUrl = 'https://core.payoutpartner.com' + file.pdfUrl;
        link.href = pdfUrl;
        link.target = '_blank';
        link.download = file.pdfFilename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      setPages() {
        const queryParams = {
          page: this.currentPage,
          items: this.filterItems
        };
        this.$router.push(this.$makeRoute({name: 'BuyerInvoices', query: queryParams}));
      },
      toPrevPage() {
        this.currentPage -= 1;
        this.setPages();
      },
      toNewPage(page) {
        this.currentPage = +page;
        this.$store.state.TTInvoicesStore.customerInvoices.currentPage = +page;
        this.setPages();
      },
      toNextPage() {
        this.currentPage += 1;
        this.setPages();
      },
    },
    beforeMount() {
      if (this.$route.query.items) this.$store.commit('setFilterItemsAmount', this.$route.query.items);
    },
    beforeDestroy() {
      this.removeCustomerInvoicesData();
      this.clearFilters();
    }
  };
</script>

<style>
  .sort-by-filter__select .sk-select {
    max-width: 150px;
    height: auto;
    padding-right: 15px;
    border: 0;
    font-size: 12px;
  }

  .bold-table-cell {
    font-weight: bold;
  }

  .break-all-words {
    word-break: break-all;
  }
</style>

<style scoped>
.invoices {
  padding: 0;
}

.invoices__header {
  display: flex;
  align-items: center;
  padding: 15px 20px;
}

.sort-by-filter {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-left: auto;
  font-size: 12px;
}

.sort-by-filter__text {
  color: var(--cool-gray-900);
  text-align: right;
}

.invoices__export-button {
  width: auto;
  padding-right: 15px;
  padding-left: 35px;
  background-image: url(~@assets/imgs/undefined_imgs/export_excel_doc_icon.svg);
  background-position: 7% 50%;
  background-size: 22px auto;
  background-repeat: no-repeat;
}

.invoices__filters {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
}

.invoices__search-filter {
  width: 100%;
  max-width: 25%;
  margin-right: 20px;
  margin-bottom: 0;
}

.invoices__search-filter:last-child {
  margin-right: 0;
}

.invoices__reference-filter {
  width: 100%;
  max-width: 22%;
}

.service-filter {
  width: 100%;
  max-width: 16%;
}

.invoices__date-filter {
  width: 30%;
}

.date-filter__datepicker {
  width: 100%;
  max-width: 100px;
}

.date-filter__hyphen {
  width: 5px;
  height: 1px;
  background-color: #000;
}

.invoices__table {
  font-size: 12px;
}

.invoices__pagination-cont {
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 20px;
}

.invoices__item-per-page {
  margin-right: auto;
}

/* empty table */
.invoices-table__empty-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;
}

.invoices-table__empty-block-image {
  width: 96px;
  height: 96px;
  background-image: url(~@assets/imgs/undefined_imgs/dashboard_icon.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.invoices-table__empty-block-subtitle {
  margin: 24px 0 8px 0;
  font-size: 20px;
}

.invoices-table__empty-block-text {
  width: 400px;
  color: var(--black-500);
  text-align: center;
}

@media (max-width: 1024px) {
  .invoices__filters {
    padding: 10px;
  }

  .invoices__pagination-cont {
    flex-direction: column;
  }

  .invoices__item-per-page {
    margin: 0 auto 10px;
  }
}

@media (max-width: 767px) {
  .invoices__filters {
    flex-wrap: wrap;
  }

  .sort-by-filter__text {
    margin-bottom: 15px;
  }

  .service-filter {
    margin-bottom: 0;
  }

  .invoices__date-filter {
    width: 35%;
  }

  .invoices__search-filter {
    width: 100%;
    max-width: initial;
    margin-top: 10px;
    margin-right: 0;
  }
}
</style>
